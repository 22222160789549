import axios from 'axios';
import store from '@/store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
/**  REQUEST INTERCEPTOR  */
axios.interceptors.request.use(
    async (config) => {
        let headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        let jwt = store.state.user?.accessToken;
        if (!jwt) {
            jwt = localStorage.getItem('token')
        }

        // add token if available and no custom Authorization added
        if (jwt !== null && !config.headers.Authorization)
            headers = {
                ...headers,
                Authorization: `Bearer ${jwt}`,
            };
        let pagination = {};
        if (config.method === 'get') {
            pagination = {
                per_page: config?.params?.per_page || 10,
            };
        }
        return {
            ...config,
            headers: {
                ...config.headers,
                ...headers,
            },
            params: {
                ...config.params,
                ...pagination,
            },
        };
    },
    (error) => {
        return Promise.reject(error);
    }
);
