<template>
  <div class="manage-container">
    <h1>
      <v-icon x-large>
        mdi-account-group
      </v-icon>
      User Management
    </h1>
    <div class="py-5">You can manage your entire registered users!</div>
    <h2>Manage Users</h2>
    <v-alert
        :type="this.success ? 'success' : 'error'"
        v-if="this.message"
    >
      {{ message }}
    </v-alert>
    <div class="pt-3">
      <v-data-table
          :headers="headers"
          :items="users"
          item-key="name"
          :items-per-page="10"
          :loading="loading"
      >
        <template v-slot:item.status="{ item }">
          <SuccessButton :status="item.status" @clickEvent="changeStatus(item)"></SuccessButton>
        </template>
        <template v-slot:item.action="{ item }">
          <ActionButtons
              :id="item.id"
              :deleteEndpoint="'/api/admin/users'"
              @deleteCallback="deleteCallback()"
              @onDeleteItem="onDeleteItem()"
          ></ActionButtons>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import SuccessButton from "../../components/Partials/SuccessButton";
import ActionButtons from "../../components/Partials/ActionButtons";

export default ({
  name: "UsersView",
  components: { SuccessButton, ActionButtons },
  data() {
    return {
      users: [],
      success: true,
      message: null,
      loading: true,
    };
  },
  computed: {
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Name', value: 'name', sortable: false, },
        { text: 'Email', value: 'email', sortable: false, },
        { text: 'Status', value: 'status', sortable: false, },
        { text: 'Actions', value: 'action', sortable: false, },
      ]
    },
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      this.loading = true;
      const users = await Api.get('api/admin/users?limit=500');
      this.users = users.data;
      this.loading = false;
    },
    async changeStatus(item) {
      let params = '';
      this.message = null;
      this.loading = true;

      if (item.id) {
        params = `/${item.id}?_method=PUT`;
      }
      const apiPost = await Api.post(
          `api/admin/users${params}`,
          null,
          { status: !item.status },
      )

      if (apiPost.success) {
        await this.fetchUsers();
        this.message = 'Record has been updated successfully!';
      } else {
        this.message = apiPost.message;
      }

      this.success = apiPost.success;
      this.loading = false;
    },
    async deleteCallback() {
      await this.fetchUsers();
      this.success = true;
      this.message = 'Record has been successfully deleted!';
    },
    onDeleteItem() {
      this.message = null;
    }
  },
});
</script>

<style lang="scss">
  .v-data-table {
    background-color: #f1f1f1 !important;
  }
</style>
