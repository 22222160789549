<template>
  <div class="manage-container col-6">
    <h1>
      <v-icon x-large>
        mdi-account-group
      </v-icon>
      User Management
    </h1>
    <div class="py-5">You can {{ `${this.id ? 'edit' : 'add new'}` }} user from here to submit this form!</div>
    <h2>{{ `${this.id ? 'Edit' : 'Add'}` }} User</h2>
    <v-alert
        :type="success ? 'success' : 'error'"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <div class="pt-3"> <validation-observer
        ref="observer"
        v-slot="{ invalid }"
    >
      <form @submit.prevent="submit">
        <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
        >
          <v-text-field
              v-model="email"
              :error-messages="errors"
              label="E-mail"
              required
              outlined
              filled
              background-color="white"
          ></v-text-field>
        </validation-provider>
        <validation-provider
            v-slot="{ errors }"
            name="Password"
            rules="required"
        >
          <v-text-field
              v-model="password"
              :error-messages="errors"
              label="Password"
              type="password"
              required
              outlined
              filled
              background-color="white"
          ></v-text-field>
        </validation-provider>

        <v-btn
            class="mr-4"
            type="submit"
            :disabled="invalid"
        >
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </form>
    </validation-observer>
    </div>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})

export default {
  name: "AddUser",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    password: '',
    email: '',
    success: true,
    message: null,
    id: null,
  }),
  methods: {
    async submit () {
      this.$refs.observer.validate()
      const response = await Api.post(
          `api/admin/users`,
          'ManageUsers',
          {
            "email": this.email,
            "password": this.password,
          },
          {headers: { 'Content-Type': 'multipart/form-data' }}
      )

      this.success = response.success;
      this.message = response.message;
    },
    clear (clearMessage = true) {
      if (clearMessage) {
        this.message = null;
      }
      this.success = true;
      this.password = ''
      this.email = ''
      this.$refs.observer.reset()
    },
  },
}
</script>

<style lang="scss">
.v-data-table {
  background-color: #f1f1f1 !important;
}
</style>
