<template>
  <div class="manage-container col-6">
    <h1>
      <v-icon x-large>
        mdi-cube-outline
      </v-icon>
      Subtopic Management
    </h1>
    <div class="py-5">You can {{ `${this.id ? 'edit' : 'add new'}` }} subtopic from here to submit this form!</div>
    <h2>{{ `${this.id ? 'Edit' : 'Add'}` }} Subtopic</h2>
    <v-alert
        :type="success ? 'success' : 'error'"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <div class="pt-3">
      <validation-observer
          ref="observer"
          v-slot="{ invalid }"
      >
        <form @submit.prevent="submit">
          <validation-provider
              v-slot="{ errors }"
              name="Display Name"
              rules="required"
          >
            <v-text-field
                v-model="title"
                :error-messages="errors"
                label="Title"
                required
                outlined
                filled
                background-color="white"
            ></v-text-field>
          </validation-provider>

          <div class="row">
            <div class="col-lg-10">
              <v-file-input
                  :rules="rules"
                  v-model="photo"
                  @change="photoPreviewImage()"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Upload a photo"
                  prepend-icon="mdi-camera"
                  label="Photo"
                  outlined
                  filled
                  background-color="white"
              ></v-file-input>
            </div>
            <div class="col-lg-2">
              <v-img :src="photoPreview" width="70"></v-img>
            </div>
          </div>

          <div>Description</div>
          <vue-editor v-model="description" class="white"></vue-editor>

          <div class="pt-5">
            <v-btn
                class="mr-4"
                type="submit"
                :disabled="invalid"
            >
              submit
            </v-btn>
            <v-btn @click="clear">
              clear
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { VueEditor } from "vue2-editor";
import formDataHelper from "../../plugins/formDataHelper";
import router from '@/router';

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  name: "AddTopic",
  components: {
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  data: () => ({
    title: '',
    description: '',
    photo: null,
    success: true,
    message: null,
    photoPreview: null,
    rules: [
      value => !!value || 'File is required',
      value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!',
    ],
    id: null,
  }),
  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      const topic = await Api.get(`api/subtopics/${this.id}`);
      this.title = topic.title;
      this.description = topic.description;
      this.photoPreview = topic.image;
    }
  },
  methods: {
    photoPreviewImage() {
      this.photoPreview = URL.createObjectURL(this.photo)
    },
    async submit () {
      this.$refs.observer.validate()
      const formData = formDataHelper.generateFormData({
        "title": this.title,
        "image": this.photo,
        "description": this.description,
      });

      let params = '';
      if (this.id) {
        params = `/${this.id}?_method=PUT`;
      }
      const topic = await Api.post(
          `api/admin/subtopics${params}`,
          'ManageSubtopics',
          formData,
          {headers: { 'Content-Type': 'multipart/form-data' }}
      )

      this.success = topic.success;
      this.message = topic.message;
    },
    clear (clearMessage = true) {
      if (clearMessage) {
        this.message = null;
      }
      this.success = true;
      this.title = ''
      this.description = ''
      this.photo = ''
      this.$refs.observer.reset()
    },
  },
}
</script>

<style lang="scss">
.v-data-table {
  background-color: #f1f1f1 !important;
}
</style>
