import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null
  },
  mutations: {
    LOGIN_SUCCESS(state, response) {
      state.user = response
      localStorage.setItem('token', response.accessToken)
    },
    LOGOUT(state) {
      state.user = null
      localStorage.removeItem( 'token' );
    },
  },
  actions: {
  },
  modules: {
  }
})
