<template>
  <div class="manage-container col-6">
    <h1>
      <v-icon x-large>
        mdi-briefcase-outline
      </v-icon>
      Candidate Management
    </h1>
    <div class="py-5">You can {{ `${this.id ? 'edit' : 'add new'}` }} candidate from here to submit this form!</div>
    <h2>{{ `${this.id ? 'Edit' : 'Add'}` }} Candidate</h2>
    <v-alert
        :type="success ? 'success' : 'error'"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <div class="pt-3"> <validation-observer
        ref="observer"
        v-slot="{ invalid }"
    >
      <form @submit.prevent="submit">
        <validation-provider
            v-slot="{ errors }"
            name="Display Name"
            rules="required"
        >
          <v-text-field
              v-model="displayName"
              :error-messages="errors"
              label="Display Name"
              required
              outlined
              filled
              background-color="white"
          ></v-text-field>
        </validation-provider>

        <validation-provider
            v-slot="{ errors }"
            name="First Name"
            rules="required"
        >
          <v-text-field
              v-model="firstName"
              :error-messages="errors"
              label="First Name"
              required
              outlined
              filled
              background-color="white"
          ></v-text-field>
        </validation-provider>

        <validation-provider
            v-slot="{ errors }"
            name="Last Name"
            rules="required"
        >
          <v-text-field
              v-model="lastName"
              :error-messages="errors"
              label="Last Name"
              required
              outlined
              filled
              background-color="white"
          ></v-text-field>
        </validation-provider>

        <v-select
            v-model="partySelected"
            :items="parties"
            item-text="title"
            item-value="id"
            :rules="[v => !!v || 'Party is required']"
            label="Party"
            required
            outlined
            filled
            background-color="white"
        ></v-select>

        <v-select
            v-model="candidateTypeSelected"
            :items="candidateTypes"
            item-text="title"
            item-value="id"
            :rules="[v => !!v || 'Candidate Type is required']"
            label="Candidate Type"
            required
            outlined
            filled
            background-color="white"
        ></v-select>

        <v-select
            v-model="votingCardSelected"
            :items="votingCards"
            item-text="title"
            item-value="id"
            :rules="[v => !!v || 'Voting Card is required']"
            label="Voting Card"
            required
            outlined
            filled
            background-color="white"
        ></v-select>

        <v-select
            v-model="stateSelected"
            :items="states"
            item-text="name"
            item-value="id"
            label="State"
            outlined
            filled
            background-color="white"
        ></v-select>

        <v-select
            v-model="electionYear"
            :items="years"
            item-text="year"
            item-value="value"
            label="Election Year"
            outlined
            filled
            background-color="white"
        ></v-select>

        <div class="row">
          <div class="col-lg-10">
            <v-file-input
                :rules="rules"
                v-model="photo"
                @change="photoPreviewImage()"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Upload a photo"
                prepend-icon="mdi-camera"
                label="Photo"
                outlined
                filled
                background-color="white"
            ></v-file-input>
          </div>
          <div class="col-lg-2">
            <v-img :src="photoPreview" width="70"></v-img>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-lg-10">
            <v-file-input
                :rules="rules"
                v-model="vicePhoto"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Upload a photo"
                prepend-icon="mdi-camera"
                label="Vice President Photo"
                outlined
                filled
                background-color="white"
            ></v-file-input>
          </div>
          <div class="col-lg-2">
            <v-img :src="vicePhotoPreview" width="70"></v-img>
          </div>
        </div>

        <div>Description</div>
        <vue-editor v-model="description" class="white"></vue-editor>

        <div class="pt-5">
          <v-btn
              class="mr-4"
              type="submit"
              :disabled="invalid"
          >
            submit
          </v-btn>
          <v-btn @click="clear">
            clear
          </v-btn>
        </div>
      </form>
    </validation-observer>
    </div>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import formDataHelper from '../../plugins/formDataHelper'
import { VueEditor } from "vue2-editor";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})

export default {
  name: "AddUser",
  components: {
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  data: () => ({
    displayName: '',
    firstName: '',
    lastName: '',
    description: '',
    partySelected: null,
    candidateTypeSelected: null,
    votingCardSelected: null,
    stateSelected: null,
    electionYear: null,
    years: [],
    photo: null,
    photoPreview: null,
    vicePhoto: null,
    vicePhotoPreview: null,
    parties: [],
    candidateTypes: [],
    votingCards: [],
    states: [],
    success: true,
    message: null,
    rules: [
      value => !!value || 'File is required',
      value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!',
    ],
    id: null,
  }),
  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      const candidate = await Api.get(`api/candidates/${this.id}`);
      this.displayName = candidate.name;
      this.firstName = candidate.first_name;
      this.lastName = candidate.last_name;
      this.description = candidate.description;
      this.partySelected = candidate.party.id;
      this.candidateTypeSelected = candidate.candidate_types.id;
      this.votingCardSelected = candidate.voting_cards.id;
      this.electionYear = candidate.election_year;
      this.stateSelected = candidate.state.id;
      this.photoPreview = candidate.image;
    }
    this.loadYears();
    await this.fetchParties();
    await this.fetchCandidateTypes();
    await this.fetchVotingCards();
    await this.fetchStates();
  },
  methods: {
    photoPreviewImage() {
      this.photoPreview = URL.createObjectURL(this.photo)
    },
    async submit () {
      this.$refs.observer.validate()
      const formData = formDataHelper.generateFormData({
        "name": this.displayName,
        "first_name": this.firstName,
        "last_name": this.lastName,
        "description": this.description,
        "candidate_type_id": this.candidateTypeSelected,
        "party_id": this.partySelected,
        "voting_card_id": this.votingCardSelected,
        "image": this.photo,
        "state_id": this.stateSelected,
        "election_year": this.electionYear,
      });
      let params = '';
      if (this.id) {
        params = `/${this.id}?_method=PUT`;
      }
      const party = await Api.post(
          `api/admin/candidates${params}`,
          'ManageCandidates',
          formData,
          {headers: { 'Content-Type': 'multipart/form-data' }}
      )
      this.success = party.success;
      this.message = party.message;
    },
    loadYears() {
      let years = []
      const currentYear = (new Date).getFullYear();

      for (let i = (currentYear - 6); i < (currentYear + 30); i++) {
        years.push({
          value: i,
          year: i,
        })
      }

      this.years = years;
    },
    async fetchParties() {
      this.parties = await Api.get('api/admin/parties?limit=500&status=1');
    },
    async fetchCandidateTypes() {
      this.candidateTypes = await Api.get('api/admin/candidate-types');
    },
    async fetchStates() {
      let states = await Api.get('api/states');
      states.unshift({id: null, name: 'None'});

      this.states = states;
    },
    async fetchVotingCards() {
      this.votingCards = await Api.get('api/admin/voting-cards');
    },
    clear (clearMessage = true) {
      if (clearMessage) {
        this.message = null;
      }
      this.success = true;
      this.displayName = ''
      this.firstName = ''
      this.lastName = ''
      this.partySelected = null
      this.candidateTypeSelected = null
      this.votingCardSelected = null
      this.electionYear = null
      this.stateSelected = null
      this.photo = null
      this.vicePhoto = null
      this.description = null
      this.$refs.observer.reset()
    },
  },
}
</script>

<style lang="scss">
.v-data-table {
  background-color: #f1f1f1 !important;
}
</style>
