<template>
  <div class="candidate-manage-container">
    <h1>
      <v-icon x-large>
        mdi-briefcase-outline
      </v-icon>
      Questions
    </h1>
    <div class="pt-3">
      <v-data-table
          :headers="headers"
          :items="questions"
          item-key="name"
          :items-per-page="10"
      >
        <template v-slot:item.action="{ item }">
          <SuccessButton :text="'Answer'" @clickEvent="answer(item)"></SuccessButton>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialogAnswer" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Choose an answer</v-card-title>
        <v-card-actions>
          <v-radio-group v-model="radioGroup">
            <v-radio
                v-for="choice in choices"
                :key="choice.answer"
                :label="choice.label"
                :value="choice.answer"
            ></v-radio>
          </v-radio-group>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAnswerDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="answerConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import SuccessButton from "../../components/Partials/SuccessButton";

export default ({
  name: 'CandidateQuestionsView',
  components: { SuccessButton },
  data() {
    return {
      questions: [],
      selectedQuestion: null,
      dialogAnswer: false,
      radioGroup: null,
      id: null,
      choices: [
        {
          label: "I Support this : Pro",
          answer: "Pro"
        },
        {
          label: "I am Against this : Against",
          answer: "Against"
        },
        {
          label: "I Don't care about this issue : Center",
          answer: "Center"
        },
        {
          label: "I am Undecided : Undecided",
          answer: "Undecided"
        },
      ]
    };
  },
  computed: {
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Topic', value: 'topic.title', sortable: false, },
        { text: 'Sub Topic', value: 'subtopic.title', sortable: false, },
        { text: 'Question', value: 'title', sortable: false, },
        { text: 'Submitted answer', value: 'candidate_answer.answer', sortable: false, },
        { text: 'Actions', value: 'action', sortable: false, },
      ]
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      await this.fetchCandidateQuestions();
    }
  },
  methods: {
    async fetchCandidateQuestions() {
      const response = await Api.get(`api/candidates/${this.id}?answers=true`);
      this.questions = response.questions;
    },
    answer(item) {
      this.dialogAnswer = true;
      this.radioGroup = item.candidate_answer?.answer;
      this.selectedQuestion = item.id;
    },
    closeAnswerDialog() {
      this.dialogAnswer = false;
    },
    async answerConfirm() {
      const question = await Api.post(
          `api/admin/candidates/${this.id}/answer?_method=PUT`,
          null,
          {
            question_id: this.selectedQuestion,
            answer: this.radioGroup
          }
      )

      this.success = question.success;
      this.message = question.message;
      this.dialogAnswer = false;
      await this.fetchCandidateQuestions();
    }
  },
});
</script>

<style lang="scss">
.v-data-table {
  background-color: #f1f1f1 !important;
}
</style>
