<template>
  <div>
    <v-icon
        v-if="updatePathName"
        small
        class="mr-2"
        @click="editItem()"
    >
      mdi-pencil
    </v-icon>
    <v-icon
        small
        @click="deleteItem()"
    >
      mdi-delete
    </v-icon>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Are you sure you want to delete this item #{{ id }}?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import axios from 'axios';
import router from "../../router";

export default {
  name: 'ActionButtons',
  props: {
    status: { type: Boolean, default: true },
    deleteEndpoint: { type: String },
    updatePathName: { type: String },
    id: { type: Number }
  },
  data() {
    return {
      dialogDelete: false,
    }
  },
  methods: {
    editItem() {
      router.push({ name: this.updatePathName, params: { id: this.id}});
    },
    deleteItem() {
      this.$emit('onDeleteItem')
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const $this = this;
      $this.dialogDelete = false;
      axios
          .delete(`${Api.Host()}${this.deleteEndpoint}/${this.id}`)
          .then(async function () {
            $this.$emit('deleteCallback')
          })
          .catch(function (error) {
            console.error(error);
          })
    },
    closeDelete() {
      this.dialogDelete = false;
    }
  }
}
</script>
