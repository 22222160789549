<template>
  <v-row>
    <v-btn
        tile
        color="#3ec396"
        style="color: white; text-transform: capitalize;"
        @click="$emit('clickEvent')"
    >
      <span v-if="text">
        {{ text }}
      </span>
      <span v-else-if="status !== null">
        Click to {{ status ? 'Deactivate' : 'Activate' }}
      </span>
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: 'StatusButton',
  props: {
    status: {type: Boolean, default: false},
    text: {type: String, default: null}
  },
}
</script>
