import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import UsersView from '../views/Manage/UsersView.vue'
import CandidatesView from "../views/Manage/CandidatesView";
import CandidateQuestionsView from "../views/Manage/CandidateQuestionsView";
import PartiesView from "../views/Manage/PartiesView";
import TopicsView from "../views/Manage/TopicsView";
import ArticlesView from "../views/Manage/ArticlesView";
import QuestionsView from "../views/Manage/QuestionsView";
import AddUser from "../views/Add/AddUser";
import SubtopicView from "../views/Manage/SubtopicView";
import AddCandidate from "../views/Add/AddCandidate";
import AddCandidateTypes from "../views/Add/AddCandidateTypes";
import CandidateTypesView from "../views/Manage/CandidateTypesView";
import AddParty from "../views/Add/AddParty.vue";
import AddTopic from "../views/Add/AddTopic";
import AddArticle from "../views/Add/AddArticle";
import AddQuestion from "../views/Add/AddQuestion";
import AddSubtopic from "../views/Add/AddSubtopic";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import("../views/DashboardView.vue"),
    children: [
        {
          path: 'manage-users',
          name: 'ManageUsers',
          component: UsersView,
        },
        {
          path: 'add-user',
          name: 'AddUser',
          component: AddUser,
        },
        {
          path: 'edit-user/:id',
          name: 'EditUser',
          component: AddUser,
        },
        {
          path: 'manage-candidates',
          name: 'ManageCandidates',
          component: CandidatesView,
        },
        {
          path: 'add-candidate',
          component: AddCandidate,
        },
        
        {
          path: 'add-candidate-types',
          name: 'CandidateTypes',
          component: AddCandidateTypes,
        },
        {
            path: 'edit-candidate-types/:id',
            name: 'EditCandidateType',
            component: AddCandidateTypes,
        },
        {
          path: 'manage-candidate-types',
          name: 'CandidateTypesView',
          component: CandidateTypesView,
        },
        {
            path: 'edit-candidate/:id',
            name: 'EditCandidate',
            component: AddCandidate,
        },
        {
          path: 'manage-candidates/questions/:id',
          name: 'CandidateQuestionsView',
          component: CandidateQuestionsView,
        },
        {
          path: 'manage-parties',
          name: 'ManageParties',
          component: PartiesView,
        },
        {
          path: 'add-party',
          component: AddParty,
        },
        {
          path: 'edit-party/:id',
          name: 'EditParty',
          component: AddParty,
        },
        {
          path: 'manage-topics',
          name: 'ManageTopics',
          component: TopicsView,
        },
        {
          path: 'add-topic',
          component: AddTopic,
        },
        {
          path: 'edit-topic/:id',
          name: 'EditTopic',
          component: AddTopic,
        },
        {
          path: 'manage-sub-topics/:id',
          name: 'ManageSubtopics',
          component: SubtopicView,
        },
        {
            path: 'add-subtopic/:id',
            name: 'AddSubtopic',
            component: AddSubtopic,
        },
        {
            path: 'edit-subtopic/:id',
            name: 'EditSubtopic',
            component: AddSubtopic,
        },
        {
          path: 'manage-articles',
          name: 'ManageArticles',
          component: ArticlesView,
        },
        {
          path: 'add-article',
            name: 'AddArticle',
          component: AddArticle,
        },
        {
           path: 'edit-article/:id',
           name: 'EditArticle',
           component: AddArticle,
        },
        {
          path: 'manage-questions',
          name: 'ManageQuestions',
          component: QuestionsView,
        },
        {
           path: 'add-question',
           component: AddQuestion,
        },
        {
           path: 'edit-question/:id',
           name: 'EditQuestion',
           component: AddQuestion,
        },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
