<template>
  <div class="manage-container col-lg-6">
    <h1>
      <v-icon x-large>
        mdi-newspaper-variant-multiple
      </v-icon>
      Article Management
    </h1>
    <div class="py-5">You can {{ `${this.id ? 'edit' : 'add new'}` }} article from here to submit this form!</div>
    <h2>{{ `${this.id ? 'Edit' : 'Add'}` }} Article</h2>
    <v-alert
        :type="success ? 'success' : 'error'"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <div class="pt-3">
      <validation-observer
          ref="observer"
          v-slot="{ invalid }"
      >
        <form @submit.prevent="submit">
          <validation-provider
              v-slot="{ errors }"
              name="Title"
              rules="required"
          >
            <v-text-field
                v-model="title"
                :error-messages="errors"
                label="Title"
                required
                outlined
                filled
                background-color="white"
            ></v-text-field>
          </validation-provider>

          <validation-provider
              v-slot="{ errors }"
              name="Site"
              rules="required"
          >
            <v-text-field
                v-model="site"
                :error-messages="errors"
                label="Site"
                required
                outlined
                filled
                background-color="white"
            ></v-text-field>
          </validation-provider>

          <validation-provider
              v-slot="{ errors }"
              name="URL"
              rules="required"
          >
            <v-text-field
                v-model="url"
                :error-messages="errors"
                label="URL"
                required
                outlined
                filled
                background-color="white"
            ></v-text-field>
          </validation-provider>
          <v-menu
              ref="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="publishedDate"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  required
                  outlined
                  filled
                  background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="publishedDate"
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(publishDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <validation-provider
              v-slot="{ errors }"
              name="Byline"
              rules="required"
          >
            <v-text-field
                v-model="byLine"
                :error-messages="errors"
                label="Byline"
                required
                outlined
                filled
                background-color="white"
            ></v-text-field>
          </validation-provider>

          <validation-provider
              v-slot="{ errors }"
              name="Source"
              rules="required"
          >
            <v-text-field
                v-model="source"
                :error-messages="errors"
                label="Source"
                required
                outlined
                filled
                background-color="white"
            ></v-text-field>
          </validation-provider>

          <v-select
              v-model="topicSelected"
              :items="topics"
              item-text="title"
              item-value="id"
              :rules="[v => !!v || 'Topic is required']"
              label="Topic"
              required
              outlined
              filled
              background-color="white"
          ></v-select>

          <v-select
              v-model="subtopicSelected"
              :items="subtopics"
              item-text="title"
              item-value="id"
              :rules="[v => !!v || 'Sub Topic is required']"
              label="Sub Topic"
              required
              outlined
              filled
              background-color="white"
          ></v-select>

          <div class="row">
            <div class="col-lg-10">
              <v-file-input
                  :rules="rules"
                  v-model="photo"
                  @change="photoPreviewImage()"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Upload a photo"
                  prepend-icon="mdi-camera"
                  label="Photo"
                  outlined
                  filled
                  background-color="white"
              ></v-file-input>
            </div>
            <div class="col-lg-2">
              <v-img :src="photoPreview" width="70"></v-img>
            </div>
          </div>

          <div>Description</div>
          <vue-editor v-model="description" class="white"></vue-editor>

          <v-container
              class="px-0"
              fluid
          >
            <v-radio-group v-model="sentiment">
              <div>Status</div>
              <v-radio
                  key="pro"
                  label="Pro"
                  :value="1"
              ></v-radio>
              <v-radio
                  key="center"
                  label="Center"
                  :value="0"
              ></v-radio>
              <v-radio
                  key="against"
                  label="Against"
                  :value="-1"
              ></v-radio>
            </v-radio-group>
          </v-container>


          <div class="pt-5">
            <v-btn
                class="mr-4"
                type="submit"
                :disabled="invalid"
            >
              submit
            </v-btn>
            <v-btn @click="clear">
              clear
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import formDataHelper from '../../plugins/formDataHelper'
import { VueEditor } from "vue2-editor";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  name: "AddArticle",
  components: {
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  data: () => ({
    title: '',
    id: null,
    site: null,
    url: null,
    byLine: null,
    source: null,
    photo: null,
    sentiment: null,
    topicSelected: null,
    subtopicSelected: null,
    publishedDate: null,
    photoPreview: null,
    description: '',
    topics: [],
    subtopics: [],
    success: true,
    // menu: false,
    message: null,
    rules: [
      value => !!value || 'File is required',
      value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!',
    ],
  }),
  async mounted() {
    await this.fetchTopics();

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      const article = await Api.get(`api/articles/${this.id}`);
      this.title = article.title;
      this.site = article.site;
      this.url = article.url;
      this.byLine = article.byline;
      this.source = article.source;
      this.sentiment = article.sentiment;
      this.topicSelected = article.topic_id;
      this.subtopicSelected = article.sub_topic_id;
      this.publishedDate = article.publish_date;
      this.description = article.description;
      this.photoPreview = article.image;
    }
  },
  watch: {
    topicSelected(val) {
      const topic = this.topics.filter((topic) => topic.id === val)
      if (!topic.length) {
        return this.subtopics = null;
      }

      this.subtopics = topic[0].sub_topics;
    },
  },
  methods: {
    photoPreviewImage() {
      this.photoPreview = URL.createObjectURL(this.photo)
    },
    async submit () {
      this.$refs.observer.validate()
      const formData = formDataHelper.generateFormData({
        "title": this.title,
        "site": this.site,
        "url": this.url,
        "byLine": this.byLine,
        "source": this.source,
        "image": this.photo,
        "sentiment": this.sentiment,
        "topic_id": this.topicSelected,
        "subtopic_id": this.subtopicSelected,
        "publishedDate": this.publishedDate,
        "description": this.description,
      });
      let params = '';
      if (this.id) {
        params = `/${this.id}?_method=PUT`;
      }
      const article = await Api.post(
          `api/admin/articles${params}`,
          'ManageArticles',
          formData,
          {headers: { 'Content-Type': 'multipart/form-data' }}
      )

      this.success = article.success;
      this.message = article.message;
    },
    clear (clearMessage = true) {
      if (clearMessage) {
        this.message = null;
      }
      this.title = null;
      this.site = null;
      this.url = null;
      this.byLine = null;
      this.source = null;
      this.photo = null;
      this.sentiment = null;
      this.topicSelected = null;
      this.subtopicSelected = null;
      this.publishDate = null;
      this.description = null;

      this.$refs.observer.reset()
    },
    async fetchTopics() {
      this.topics = await Api.get(`api/topics?sub_topics=true`);
    },
  },
}
</script>

<style lang="scss">
.v-data-table {
  background-color: #f1f1f1 !important;
}
</style>
