<template>
  <div class="manage-container col-6">
    <h1>
      <v-icon x-large>
        mdi-cube-outline
      </v-icon>
      Candidate Types Management
    </h1>
    <div class="py-5">You can {{ `${this.id ? 'edit' : 'add new'}` }} candidate type from here to submit this form!</div>
    <h2>{{ `${this.id ? 'Edit' : 'Add'}` }} Candidate Type</h2>
    <v-alert
        :type="success ? 'success' : 'error'"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <div class="pt-3">
      <validation-observer
          ref="observer"
          v-slot="{ invalid }"
      >
        <form @submit.prevent="submit">
          <validation-provider
              v-slot="{ errors }"
              name="Type"
              rules="required"
          >
            <v-text-field
                v-model="type"
                :error-messages="errors"
                label="Type"
                required
                outlined
                filled
                background-color="white"
            ></v-text-field>
          </validation-provider>
          <v-select
            v-model="status"
            :items="statuses"
            item-text="status"
            item-value="value"
            label="Status"
            required
            outlined
            filled
            background-color="white"
        ></v-select>
          <div class="pt-5">
            <v-btn
                class="mr-4"
                type="submit"
                :disabled="invalid"
            >
              submit
            </v-btn>
            <v-btn @click="clear">
              clear
            </v-btn>
          </div>
        </form>
      </validation-observer>
      
    </div>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { VueEditor } from "vue2-editor";
import formDataHelper from "../../plugins/formDataHelper";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  name: "AddTopic",
  components: {
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  data: () => ({
    type: '',
    status: 1,
    id: null,
    statuses: [
      {
        value: 1,
        status: 'Enabled',
      },
      {
        value: 0,
        status: 'Disabled',
      },
    ],
    message: null,
  }),
  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;

      const type = await Api.get(`api/admin/candidate-type/${this.id}`);
      this.type = type.title;
      this.status = type.status ? 1 : 0;
    }
  },
  methods: {
    async submit () {
      this.$refs.observer.validate()
      const formData = formDataHelper.generateFormData({
        "title": this.type,
        "status": this.status,
      });

      let params = '';
      if (this.id) {
        params = `/${this.id}?_method=PUT`;
      }
      const topic = await Api.post(
          `api/admin/candidate-type${params}`,
          'CandidateTypesView',
          formData,
          {headers: { 'Content-Type': 'multipart/form-data' }}
      )

      this.success = topic.success;
      this.message = topic.message;
    },
    clear (clearMessage = true) {
      if (clearMessage) {
        this.message = null;
      }
      this.success = true;
      this.title = ''
      this.description = ''
      this.photo = ''
      this.$refs.observer.reset()
    },
  },
}
</script>

<style lang="scss">
.v-data-table {
  background-color: #f1f1f1 !important;
}
</style>
