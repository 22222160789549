<template>
  <div class="candidate-manage-container">
    <h1>
      <v-icon x-large>
        mdi-briefcase-outline
      </v-icon>
      Candidate Management
    </h1>
    <div class="py-5">You can manage your entire registered candidates!</div>
    <h2>Manage Candidates</h2>
    <v-alert
        type="success"
        v-if="alertNotification"
    >
      {{ alertNotification.message }}
    </v-alert>
    <div class="pt-3">
      <v-data-table
          :headers="headers"
          :items="candidates"
          item-key="name"
          :items-per-page="10"
          :loading="loading"
      >
        <template v-slot:item.image="{ item }">
          <img :src="item.image" width="50" height="50">
        </template>
        <template v-slot:item.answer="{ item }">
          <SuccessButton :text="'Add answers'" @clickEvent="addAnswers(item.id)"></SuccessButton>
        </template>
        <template v-slot:item.action="{ item }">
          <ActionButtons
              :id="item.id"
              :deleteEndpoint="'/api/admin/candidates'"
              :updatePathName="'EditCandidate'"
              @deleteCallback="deleteCallback()"
              @onDeleteItem="onDeleteItem()"
          ></ActionButtons>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import SuccessButton from "../../components/Partials/SuccessButton";
import ActionButtons from "../../components/Partials/ActionButtons";
import router from '@/router';

export default ({
  name: "CandidatesView",
  components: { SuccessButton, ActionButtons },
  data() {
    return {
      candidates: [],
      alertNotification: false,
      loading: true,
    };
  },
  computed: {
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Display Name', value: 'name', sortable: false, },
        { text: 'First Name', value: 'first_name', sortable: false, },
        { text: 'Last Name', value: 'last_name', sortable: false, },
        { text: 'Party', value: 'party_name', sortable: false, },
        { text: 'Candidate Type', value: 'candidate_types.title', sortable: false, },
        { text: 'Voting Card', value: 'voting_cards.title', sortable: false, },
        { text: 'Election Year', value: 'election_year', sortable: false, },
        { text: 'Image', value: 'image', sortable: false, },
        { text: '', value: 'answer', sortable: false, },
        { text: 'Actions', value: 'action', sortable: false, },
      ]
    },
  },
  mounted() {
    this.fetchCandidates();
  },
  methods: {
    async fetchCandidates() {
      this.loading = true;
      this.candidates = await Api.get('api/candidates?limit=500');
      this.loading = false;
    },
    async deleteCallback() {
      await this.fetchCandidates();
      this.alertNotification = {
        type: 'success',
        message: 'Record has been successfully deleted!',
      };
    },
    onDeleteItem() {
      this.alertNotification = false;
    },
    addAnswers(id) {
      router.push({name: 'CandidateQuestionsView', params: { id: id}});
    }
  },
});
</script>

<style lang="scss">
.v-data-table {
  background-color: #f1f1f1 !important;
}
</style>
