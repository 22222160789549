// This function checks the hostname and returns the environment
import axios from "axios";
import formDataHelper from "./formDataHelper";
import router from "../router";

export default {
	Host() {
		let hosts = {
			testing: "https://povnews.test",
			staging: "https://dev.thepovnews.com",
			production: "https://api.thepovnews.com",
			local: "http://localhost:8000"
		};

		return hosts["staging"];
	},
	post(uri, route, ...rest) {
		const $this = this;
		return axios
			.post(`${this.Host()}/${uri}`, ...rest)
			.then(function (response) {
				$this.onSubmit(route);
				return response.data;
			})
			.catch(function (error) {
				if (error.response.status === 401) {
					router.push({ name: 'login' })
				} else {
					$this.onSubmit();
					return error.response.data;
				}
			});
	},
	get(uri) {
		return axios.get(`${this.Host()}/${uri}`)
			.then(function (response) {
				return response.data.data;
			})
			.catch(function (error) {
				if (error.response.status === 401) {
					router.push({ name: 'login' })
				}
			});
	},
	onSubmit(route = null) {
		formDataHelper.scrollToTop();
		if (route) {
			setTimeout(() => router.push({ name: route }), 1500);
		}
	},
}
