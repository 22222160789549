<template>
  <div class="candidate-types-manage-container">
    <h1>
      <v-icon x-large>
        mdi-briefcase-outline
      </v-icon>
      Candidate Types Management
    </h1>
    <div class="py-5">You can manage your entire candidates types!</div>
    <h2>Manage Candidate Types</h2>
    <v-alert
        type="success"
        v-if="alertNotification"
    >
      {{ alertNotification.message }}
    </v-alert>
    <div class="pt-3">
      <v-data-table
          :headers="headers"
          :items="types"
          item-key="name"
          :items-per-page="10"
          :loading="loading"
      >
        <template v-slot:item.status="{ item }">
          {{ item.status ? 'Enabled' : 'Disabled' }}
        </template>
        <template v-slot:item.action="{ item }">
          <ActionButtons
              :id="item.id"
              :deleteEndpoint="'/api/admin/candidate-type'"
              :updatePathName="'EditCandidateType'"
              @deleteCallback="deleteCallback()"
              @onDeleteItem="onDeleteItem()"
          ></ActionButtons>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import ActionButtons from "../../components/Partials/ActionButtons";

export default ({
  name: "CandidatesView",
  components: { ActionButtons },
  data() {
    return {
      types: [],
      alertNotification: false,
      loading: true,
      message: null,
    };
  },
  computed: {
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Title', value: 'title', sortable: false, },
        { text: 'Status', value: 'status', sortable: false, },
        { text: 'Actions', value: 'action', sortable: false, },
      ]
    },
  },
  mounted() {
    this.fetchCandidateTypes();
  },
  methods: {
    async fetchCandidateTypes() {
      this.loading = true;
      this.types = await Api.get('api/admin/candidate-type');
      this.loading = false;
    },
    async deleteCallback() {
      await this.fetchCandidateTypes();
      this.alertNotification = {
        type: 'success',
        message: 'Record has been successfully deleted!',
      };
    },
    onDeleteItem() {
      this.alertNotification = false;
    }
  },
});
</script>

<style lang="scss">
.v-data-table {
  background-color: #f1f1f1 !important;
}
</style>
