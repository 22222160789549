<template>
  <div class="home">
  </div>
</template>

<script>
// @ is an alias to /src

import router from '@/router';

export default {
  name: 'Home',
  beforeCreate() {
    router.push( { name: 'login' } );
  },
}
</script>
