<template>
  <div class="manage-container col-6">
    <h1>
      <v-icon x-large>
        mdi-tag
      </v-icon>
      Question Management
    </h1>
    <div class="py-5">You can {{ `${this.id ? 'edit' : 'add new'}` }} question from here to submit this form!</div>
    <h2>{{ `${this.id ? 'Edit' : 'Add'}` }} Question</h2>
    <v-alert
        :type="success ? 'success' : 'error'"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <div class="pt-3">
      <validation-observer
          ref="observer"
          v-slot="{ invalid }"
      >
        <form @submit.prevent="submit">
          <validation-provider
              v-slot="{ errors }"
              name="Title"
              rules="required"
          >
            <v-text-field
                v-model="title"
                :error-messages="errors"
                label="Title"
                required
                outlined
                filled
                background-color="white"
            ></v-text-field>
          </validation-provider>

          <v-select
              v-model="topicSelected"
              :items="topics"
              item-text="title"
              item-value="id"
              :rules="[v => !!v || 'Topic is required']"
              label="Topic"
              required
              outlined
              filled
              background-color="white"
          ></v-select>

          <v-select
              v-model="subtopicSelected"
              :items="subtopics"
              item-text="title"
              item-value="id"
              :rules="[v => !!v || 'Sub Topic is required']"
              label="Sub Topic"
              required
              outlined
              filled
              background-color="white"
          ></v-select>

          <div>Description</div>
          <vue-editor v-model="description" class="white"></vue-editor>

          <div class="pt-5">
            <v-btn
                class="mr-4"
                type="submit"
                :disabled="invalid"
            >
              submit
            </v-btn>
            <v-btn @click="clear">
              clear
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import Api from "../../plugins/Api";
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import formDataHelper from "../../plugins/formDataHelper";
import { VueEditor } from "vue2-editor";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  name: "AddQuestion",
  components: {
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  data: () => ({
    title: '',
    description: '',
    topicSelected: null,
    subtopicSelected: null,
    topics: [],
    subtopics: [],
    id: null,
    success: true,
    message: null,
  }),
  async mounted() {
    await this.fetchTopics();

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      const question = await Api.get(`api/admin/questions/${this.id}?topic=true&sub_topic=true`);
      this.title = question.title;
      this.description = question.description;
      this.topicSelected = question.topic.id;
      this.subtopicSelected = question.subtopic.id;
      this.photoPreview = question.image;
    }
  },
  watch: {
    topicSelected(val) {
      const topic = this.topics.filter((topic) => topic.id === val)
      if (!topic.length) {
        return this.subtopics = null;
      }

      this.subtopics = topic[0].sub_topics;
    },
  },
  methods: {
    async submit () {
      this.$refs.observer.validate()
      const formData = formDataHelper.generateFormData({
        "title": this.title,
        "topic_id": this.topicSelected,
        "subtopic_id": this.subtopicSelected,
        "description": this.description,
      });

      let params = '';
      if (this.id) {
        params = `/${this.id}?_method=PUT`;
      }
      const article = await Api.post(
          `api/admin/questions${params}`,
          'ManageQuestions',
          formData,
          {headers: { 'Content-Type': 'multipart/form-data' }}
      )

      this.success = article.success;
      this.message = article.message;
    },
    clear (clearMessage = true) {
      if (clearMessage) {
        this.message = null;
      }
      this.success = true;
      this.title = ''
      this.topic_id = ''
      this.subtopic_id = ''
      this.description = ''
      this.$refs.observer.reset()
    },
    async fetchTopics() {
      this.topics = await Api.get(`api/topics?sub_topics=true&status=1`);
    },
  },
}
</script>

<style lang="scss">
.v-data-table {
  background-color: #f1f1f1 !important;
}
</style>
